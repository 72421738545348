import request from '@/utils/request';


// 修改密码
export function ChangePwd(data) {
  return request({
    url: '/wisc/youth/baseInfo/changePwd',
    method: 'POST',
    data
  });
}
