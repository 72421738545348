<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <topMenu :num="3"></topMenu>
    </div>
    <div class="main-body">
      <div class="content-box">
        <div class="tabs-box">
          <div class="tabs-item" @click="goModifyData">个人资料修改</div>
          <div class="tabs-item active">修改密码</div>
          <div class="tabs-item" @click="goHistoryReport">历史报表查询</div>
          <div class="tabs-item" style="margin-left: 20px;width: 220px"><a style="color: #FFFFFF" href="http://wisc.king-may.com.cn/login.jhtml" target="_blank">2024年9月2日前报告查询</a></div>
        </div>
        <div class="form-box">
          <a-form-model ref="form" :model="formModel"  :rules="formRules" autoComplete="off" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="主试姓名">
                  <span class="name">{{ name }}</span>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="旧密码" prop="oldPwd">
                  <a-input allowClear type="password" class="cu-input1" v-model="formModel.oldPwd"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="11"></a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="新密码" prop="newPwd">
                  <a-input allowClear type="password" class="cu-input1" v-model="formModel.newPwd"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="8" style="padding-top: 6px"><span class="de">* 为保证安全使用英文字母加数字或符号的组合</span></a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="重输新密码" prop="newPwd1">
                  <a-input allowClear type="password" class="cu-input1" v-model="formModel.newPwd1"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="8" style="padding-top: 8px"><span class="de">* 请再输入一遍您上面输入的新密码</span></a-col>
            </a-row>
            <a-row style="border-top: 1px solid #E9ECF2;padding-top: 40px;margin-top: 40px">
              <a-col :span="24" style="display: flex;justify-content: center;align-items: center;cursor: pointer">
                <span class="btn" @click="changePwd">保存</span>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import * as Api from './api';
import {message} from "ant-design-vue";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (this.formModel.newPwd != this.formModel.newPwd1) {
        callback(new Error('密码输入不一致'));
      }
      callback();
    };
    return {
      labelCol: {span: 6, style: {color:'#7E8589',fontWeight: '500'}},
      wrapperCol: {span: 16},
      formModel: {
        oldPwd: '',
        newPwd: '',
        newPwd1: ''
      },
      formRules: {
        oldPwd: [
          { required: true, message: '请输旧密码', trigger: 'change' },
        ],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'change' },
        ],
        newPwd1: [
          { required: true, message: '请再次输入新密码', trigger: 'change' },
          { validator: validatePass, trigger: 'change' },
        ]
      },
      name: ''
    };
  },
  mounted() {
    let abas_user = JSON.parse(window.localStorage.getItem('abas_user'));
    this.name = abas_user.name;
  },
  methods: {
    async changePwd() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const res = await Api.ChangePwd(this.formModel);
          if (res && res.code == 0) {
            message.success({ content: res.message});
            window.localStorage.removeItem('login_code');
            setTimeout(()=> {
              this.$router.push({ path: "/" });
            }, 1500);
          }
        }
      });
    },
    goModifyData() {
      this.$router.push({path: '/maintain-data-modify'});
    },
    goHistoryReport() {
      this.$router.push({path: '/maintain-history-report'});
    }
  }
};
</script>
<style>

</style>
<style scoped>
.main-body {
  background-image: url("/images/maintain-bg.png");
  align-items: flex-start;
  justify-content: flex-start;
}
.content-box {
  //margin: 50px;
  width: 100%;
}

.form-box {
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0px 8px 25px 0px rgba(242, 243, 245, 0.28);
  border-radius: 0px 12px 12px 12px;
}
.btn {
  background: #832B75;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  padding: 10px 40px;
}
.name {
  color: #656565;
  font-size: 14px;
  font-weight: 400;
  color: #4A4E51;
}
.de {
  font-size: 12px;
  font-weight: 500;
  color: #7E8589;
  opacity: 0.89;
}
</style>
